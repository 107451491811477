// extracted by mini-css-extract-plugin
export var Forminput = "styles-module--Forminput--1a220";
export var alert = "styles-module--alert--0aae9";
export var alertFailure = "styles-module--alertFailure--973a7";
export var alertSuccess = "styles-module--alertSuccess--504be";
export var box = "styles-module--box--adbc7";
export var buttonSubmit = "styles-module--buttonSubmit--ad99e";
export var heading = "styles-module--heading--ec323";
export var middle = "styles-module--middle--2e080";
export var overlay = "styles-module--overlay--3bc32";
export var textarea = "styles-module--textarea--c67e6";