import React, { useState } from "react"
import { SectionHeading } from "components"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import axios from "axios"
import { HIRE_EXPERT_FORM_API_ENDPOINT } from "constants"
import { Spinner } from "components"
import { navigate } from "gatsby"
import emailjs from "@emailjs/browser"

const SelectExpert = () => {
	const [lookingFor, setLookingFor] = useState("")
	const [timeline, setTimeline] = useState("")
	const [resourceType, setResourceType] = useState("")
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [projectDetails, setProjectDetails] = useState("")
	const [isSending, setIsSending] = useState(false)
	const [formSuccess, setFormSuccess] = useState()

	const handleFormSubmit = (e) => {
		e.preventDefault()
		setIsSending(true)
		let requestPayload = {
			lookingFor: lookingFor,
			timeline: timeline,
			resourceType: resourceType,
			name: name,
			email: email,
			projectDetails: projectDetails,
		}
		emailjs
			.send("service_hh9figm", "template_biomxpo", requestPayload, {
				publicKey: "tlqQO1w-M4WEQ37br",
			})
			.then(
				() => {
					console.log("SUCCESS!")
					setIsSending(false)
					setFormSuccess("OK")
					setLookingFor("")
					setTimeline("")
					setResourceType("")
					setName("")
					setEmail("")
					setProjectDetails("")
					navigate("/schedule-call/")
				},
				(error) => {
					console.log("FAILED...", error.text)
					setIsSending(false)
					setFormSuccess("FAIL")
				}
			)
	}
	return (
		<Container className="mt-5 ">
			<Row>
				<Col md={7}>
					<SectionHeading className="text-md-start text-center mb-0">HIRE TOP DESIGNERS & DEVELOPERS</SectionHeading>
					<Row>
						<Col md={10}>
							<Form autoComplete="off" onSubmit={handleFormSubmit}>
								<Row>
									{isSending ? <div className={styles.overlay}> </div> : ""}
									<Col md={12}>
										<h2 className={styles.heading}>What role would you like to hire for?</h2>
										<div className={styles.middle}>
											<Form.Label>
												<Form.Control
													value="Designer"
													onChange={(e) => setLookingFor(e.target.value)}
													type="radio"
													name="role"
													required
												/>
												<div className={styles.box}>
													<span>Designer</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													value="Developer"
													onChange={(e) => setLookingFor(e.target.value)}
													type="radio"
													name="role"
												/>
												<div className={styles.box}>
													<span>Developer</span>
												</div>
											</Form.Label>
										</div>
										<h2 className={styles.heading}>How long do you need the resource for?</h2>
										<div className={styles.middle}>
											<Form.Label>
												<Form.Control
													value="1-3 Months"
													type="radio"
													name="time"
													onChange={(e) => setTimeline(e.target.value)}
													required
												/>
												<div className={styles.box}>
													<span>1-3 Months</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													value="3-6 Months"
													type="radio"
													name="time"
													onChange={(e) => setTimeline(e.target.value)}
												/>
												<div className={styles.box}>
													<span>3-6 Months</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													value="6+ Month"
													type="radio"
													name="time"
													onChange={(e) => setTimeline(e.target.value)}
												/>
												<div className={styles.box}>
													<span>6+ Months</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													value="Decide-later"
													type="radio"
													name="time"
													onChange={(e) => setTimeline(e.target.value)}
												/>
												<div className={styles.box}>
													<span>I will decide later</span>
												</div>
											</Form.Label>
										</div>
										<h2 className={styles.heading}>
											What level of time commitment will you require from the resource?
										</h2>
										<div className={styles.middle}>
											<Form.Label>
												<Form.Control
													type="radio"
													name="type"
													value="Full Time"
													onChange={(e) => setResourceType(e.target.value)}
													required
												/>
												<div className={styles.box}>
													<span>Full Time</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													type="radio"
													name="type"
													value="Part Time"
													onChange={(e) => setResourceType(e.target.value)}
												/>
												<div className={styles.box}>
													<span>Part Time</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													type="radio"
													name="type"
													value="Hourly"
													onChange={(e) => setResourceType(e.target.value)}
												/>
												<div className={styles.box}>
													<span>Hourly</span>
												</div>
											</Form.Label>
											<Form.Label>
												<Form.Control
													type="radio"
													name="type"
													value="Decide-later"
													onChange={(e) => setResourceType(e.target.value)}
												/>
												<div className={styles.box}>
													<span>I will decide later</span>
												</div>
											</Form.Label>
										</div>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
											<Form.Control
												className={styles.Forminput}
												type="text"
												value={name}
												placeholder="Your Name"
												onChange={(e) => setName(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
											<Form.Control
												className={styles.Forminput}
												type="email"
												value={email}
												placeholder="Your Email"
												onChange={(e) => setEmail(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3 " controlId="formBasicEmail">
											<Form.Control
												as="textarea"
												rows={4}
												className={`${styles.Forminput} ${styles.textarea}`}
												type="text"
												placeholder="Project Details"
												value={projectDetails}
												onChange={(e) => setProjectDetails(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>

									<Col md={12}>
										<div className="text-md-end text-center">
											<Button type="submit" className={styles.buttonSubmit}>
												{isSending ? <Spinner /> : "Submit"}
											</Button>
										</div>
										{formSuccess === "FAIL" && (
											<div className={`${styles.alert} ${styles.alertFailure}`}>
												Something went wrong, please try again.
											</div>
										)}
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Col>
				<Col md={5} className="mt-5 pt-5 d-none d-md-block">
					<h2 className={styles.heading}> Tell us about your vision</h2>
					<ul>
						<li>What challenges are you facing?</li>
						<li>What are your goals and expectations?</li>
						<li>What would success look like?</li>
						<li>How much are you planning to spend  to get there?</li>
					</ul>
				</Col>
			</Row>
		</Container>
	)
}

export default SelectExpert
