import React from "react"
import { Button, Main, SectionHeading } from "components"
import { Col, Container, Row } from "react-bootstrap"
import SelectExpert from "components/pages/hire-expert/SelectExpert"

const HireExpert = () => {
	return (
		<Main>
			<SelectExpert />
		</Main>
	)
}

export default HireExpert
